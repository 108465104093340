import React, { useState, useEffect} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter, dateFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, {Type} from 'react-bootstrap-table2-editor';
import { JournalText } from 'react-bootstrap-icons';
import Modal from 'react-modal';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import overlayFactory from 'react-bootstrap-table2-overlay';


import AppBar from './AppBar';

import client, { getUser } from './utils/api'; 
import { Button, CircularProgress, Snackbar } from '@material-ui/core';

import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

const { ExportCSVButton } = CSVExport;

const o = {
    // 'Pas de projet/injoignable': 0,
    'Projet': 1,
    'Client': 2,
    'vide': 3,
    'Mauvais': 7,
    'Injoignable': 8,
    'Perdu': 9,
    'Petit budget': 5
}

const PRODUCT_LIST = [
    '',
    'J200',
    'J300',
    'J400',
    'J500',
    'Italian Design',
    'Débordement',
    'Nage Active',
    'Nage Pro',
    'Nage Play',
    'Divers'
]

const statusOption = [
    {
        value: 'vide',
        label: 'Non traité'
    },{
    value: 'Projet',
    label: 'Projet'
  },
  {
    value: 'Client',
    label: 'Client'
  },
  {
    value: 'Mauvais',
    label: 'Mauvais'
  },
  {
    value: 'Injoignable',
    label: 'Injoignable'
  },
  {
    value: 'Perdu',
    label: 'Perdu'
  },
  {
    value: 'Petit budget',
    label: 'Petit budget'
  }];



const getCurrentValue = (currentContact) => {
    if(!currentContact) {
        return 3;
    }
    
    if(currentContact.Petit_budget__c) {
        return 5;
    }

    if(currentContact.Qualification__c === "Unqualified" && currentContact.Unqualified_d_tails__c === "Mauvais") {
        return 0;
    } else if(currentContact.Qualification__c === "Project") {
        return 1;
    }  else if(currentContact.Qualification__c === "Client") {
        return 2;
    } else if(currentContact.Qualification__c === "No Sale") {
        if(currentContact.Detailsnosale__c === 'Petit budget') {
            return 5;
        } else if (currentContact.Detailsnosale__c === 'Mauvais') {
            return 7;
        } else if (currentContact.Detailsnosale__c === 'Injoignable') {
            return 8;
        } else if (currentContact.Detailsnosale__c === 'Perdu') {
            return 9;
        }
    }

    return 3;
};

function getLabelFromOption(val) {
    switch (val) {
        case 1:
            return 'Projet';
        case 2:
            return 'Client';
        case 3:
            return 'Non traité';
        case 7:
            return 'Mauvais';
        case 8:
            return 'Injoignable';
        case 9:
            return 'Perdu';
        case 5: 
            return 'Petit budget'
    };
}

const mongoFilterMap = {
    '>': "$gt",
    '>=': "$gte",
    '<': "$lt",
    '<=': "$lte",
};

function getMongoFilter(filters) {
    const result = {};
    if(filters.relaunchDate) {
        const filter = filters.relaunchDate;
        result.relaunchDate = {};
        result.relaunchDate[mongoFilterMap(filter.filterVal.comparator)] = filter.filterVal.date;
    }
    return result;
}

const App = () => {
  const [contacts, setContacts] = useState([]);
  const [page, setPage] = useState(1);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [totalSize, setTotalSize] = useState(0);
  const [sizePerPageState, setSizePerPage] = useState(50);
  const [savedFilters, setSavedFilters] = useState({});
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [currentCustomer, setCurrentCustomer] = React.useState(false);
  const [ notes, setNotes ] = React.useState("");
  const [isSavingNotes, setIsSavingNotes] = React.useState(false);
  const [isSavingContact, setIsSavingContact] = React.useState(false);
  const [isSavingContactSuccess, setIsSavingContactSuccess] = React.useState(false);
  const [isSavingContactFailure, setIsSavingContactFailure] = React.useState(false);


  function openModal(customerId) {
    const contact = contacts.find(c => c.Id === customerId);
    setNotes(contact?.notes || "");
    setCurrentCustomer(contact);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const columns = [{
    dataField: 'Email',
    text: 'Email',
    filter: textFilter(),
    sort: true,
    editable: false,
    style: {
        width: 200
    }
   },
   {
      dataField: 'created_dateDB__c',
      text: 'Date de création',
      filter: dateFilter({
          withoutEmptyComparatorOption: true,
          comparators: [
              '>',
              '>=',
              '<',
              '<='
          ]
      }),
      sort: false,
      editable: false,
     },
   {
      dataField: 'LastName',
      text: 'Nom',
      filter: textFilter(),
      sort: true,
      editable: false
     },
     {
      dataField: 'FirstName',
      text: 'Prénom',
      filter: textFilter(),
      sort: true,
      editable: false
     },
     {
      dataField: 'Phone',
      text: 'Téléphone',
      filter: textFilter(),
      sort: true,
      editable: false
     },
     {
      dataField: 'MobilePhone',
      text: 'Téléphone 2',
      filter: textFilter(),
      sort: true,
      editable: false
     },
     {
      dataField: 'MailingPostalCode',
      text: 'Code postal',
      filter: textFilter(),
      sort: false,
      editable: false
     },
      {
       dataField: 'status',
       text: 'Statut',
       editor: {
          type: Type.SELECT,
          options: statusOption
       },
       filter: selectFilter({
          options: statusOption
        })
      },
      {
          dataField: 'Date_relance_platform__c',
          text: 'Date de relance',
          editor: {
              type: Type.DATE,
              defaultValue: ""
            },
          filter: dateFilter({
              withoutEmptyComparatorOption: true,
              comparators: [
                  '>',
                  '>=',
                  '<',
                  '<='
              ]
          }),
          sort: true,
          editable: true,
         },
      {
          dataField: 'LeadSource',
          text: 'Source',
          filter: textFilter(),
          sort: true,
          editable: false
      },
      {
          dataField: 'Collection__c',
          text: 'Product',
          editor: {
             type: Type.SELECT,
             options: PRODUCT_LIST.map(p => ({ value: p, label: p }))
          },
          filter: selectFilter({
             options: PRODUCT_LIST.map(p => ({ value: p, label: p }))
           })
         },
         {
            dataField: 'notes',
            text: 'Notes',
            filter: textFilter(),
            sort: false,
            editable: false,
            hidden: true,
        },
        {
        dataField: "notes_action",
        text: "",
        editable: false,
        csvExport: false,
        formatter: (cellContent, row) => {
        return (
            <button
            className="btn btn-default btn-xs"
            onClick={() => {
                openModal(row.Id);
            }
            }
        >
            <JournalText />
        </button>
        );
    },
    style: {
        width: 1
    }
    }];

    const user = getUser();

    if(user?.isAdmin) {
        columns.unshift({
            dataField: 'dealerName',
            text: 'Dealer',
            filter: textFilter({
                delay: 1000
            }),
            sort: false,
            editable: false,
            style: {
                width: 200
            }
           });
    }

  const cellEdit = {
    mode: 'click',
    blurToSave: true,
    errorMessage: error
  };

  useEffect(() => {
    loadContacts({ page, limit: sizePerPageState });
  }, []);
  
  const loadContacts = async (params) => {
    try {
        setIsLoading(true);
        const c = await client.mainApp.service('contacts').find({ query: {
                ...params
            }
        });
        setTotalSize(c.totalSize);

        const records = c.records;

        if(params.limit > 200 && records?.length >= 200) {
            // Chunk call 200 by 200
            const numberOfCalls = Math.ceil(params.limit / 200);

            for(let i = 0; i < numberOfCalls; i++) {
                const res = await client.mainApp.service('contacts').find({ query: {
                        ...params,
                        offset: (params.offset || 0) + (200 * (i +1))
                    }
                });

                records.concat(res.records);

                if(res.records?.length < 200) {
                    break;
                }
            }
        }

        setContacts(records);
        setIsLoading(false);
    } catch(err) {
        window.location.href = '/login';
    }
  };

  const handleTableChange = async (type, { cellEdit, sortField, sortOrder, filters, page, sizePerPage }) => {
    if(cellEdit && cellEdit.rowId && (cellEdit.newValue || cellEdit.newValue === '')) {
        setError("");
        setIsSavingContact(true);
        setIsSavingContactSuccess(false);
        const oldObjIndex = contacts?.findIndex(c => c.Id === cellEdit.rowId);
        const oldObj = contacts[oldObjIndex];
        try {
            let updateObj = {};
            if(cellEdit.dataField === 'status') {
                updateObj.status = parseInt(o[cellEdit.newValue]);
            }

            if(cellEdit.dataField === 'Collection__c') {
                updateObj.product = cellEdit.newValue;
                updateObj.Collection__c = cellEdit.newValue;
            }

            if(cellEdit.dataField === 'Date_relance_platform__c') {
                updateObj.Date_relance_platform__c = cellEdit.newValue;
            }
            contacts[oldObjIndex] = {
                ...contacts[oldObjIndex],
                ...updateObj,
            };

            setContacts([...contacts]);
            client.mainApp.service('contacts').update(cellEdit.rowId, updateObj)
                .then((data) => {
                    if(data.success) {
                        setIsSavingContact(false);
                        setIsSavingContactSuccess(true);
                    } else {
                        contacts[oldObjIndex] = oldObj;
                        setContacts(contacts);
                        // setError("Erreur lors de la sauvegarde");
                        setIsSavingContactFailure(true);
                    }
                    setIsSavingContact(false);
                })
                .catch(err => {
                    contacts[oldObjIndex] = oldObj;
                    setContacts(contacts);
                    // setError("Erreur lors de la sauvegarde");
                    setIsSavingContact(false);
                });
        } catch(err) {
            contacts[oldObjIndex] = oldObj;
            setContacts(contacts);
            // setError("Erreur lors de la sauvegarde");
        }

        // return loadContacts({ limit: sizePerPage || 10, ...savedFilters });

        return;
    }

    const {status, ...otherFilters} = filters
    const apiFilters = {...otherFilters};

    const specialFilters = {};

    if(status) {
        switch(status.filterVal) {
            case 'Projet':
                apiFilters.Qualification__c = {filterVal: "Project"};
                break;
            case 'Client':
                apiFilters.Qualification__c = {filterVal: "Client"};
                break;
            case 'Perdu':
                apiFilters.Qualification__c = {filterVal: "No Sale"};
                apiFilters.Detailsnosale__c = {filterVal: "Perdu"};
                break;
            case 'vide':
                apiFilters.Qualification__c = {filterVal: "New Lead"};
                break;
            case 'Petit budget':
                apiFilters.Qualification__c = {filterVal: "No Sale"};
                apiFilters.Detailsnosale__c = {filterVal: "Petit budget"};
                break;
            case 'Injoignable':
                apiFilters.Qualification__c = {filterVal: "No Sale"};
                apiFilters.Detailsnosale__c = {filterVal: "Injoignable"};
                break;
            case 'Mauvais':
                apiFilters.Qualification__c = {filterVal: "No Sale"};
                apiFilters.Detailsnosale__c = {filterVal: "Mauvais"};
                break;
        }
    } else {
        apiFilters.Qualification__c = undefined;
        apiFilters.Unqualified_d_tails__c = undefined;
    }

    setSizePerPage(sizePerPage)

    loadContacts({ page, limit: sizePerPage || 10, filters: apiFilters, sortField, sortOrder, ...specialFilters });


    setSavedFilters({ sortField, sortOrder, filters: apiFilters, page, sizePerPage })
    setPage(page);
  };

  const paginationTotalRenderer = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total" style={{marginLeft: 20, marginTop: 5}}>
      <span>{ from } à { to } sur { size } Résultats</span>
    </span>
  );

  const saveNotes = async () => {
    setIsSavingNotes(true);
    await client.mainApp.service('contacts').update(currentCustomer.Id, {
        notes
    });

    await loadContacts({ limit: sizePerPageState || 10, ...savedFilters });

    setIsSavingNotes(false);
    closeModal();
  };

  return (
    <React.Fragment>
        <AppBar name={user?.name} email={user?.email} />
        <div className="scrollTable">
            <button
                className="btn btn-default btn-xs"
                onClick={() => {
                  setSavedFilters({});
                  loadContacts({ page: 1, limit: sizePerPageState });
                }
              }
            >Effacer les filtres</button>
            <ToolkitProvider
                keyField="Id"
                data={ contacts ? contacts.map(c => ({
                    ...c,
                    status: !c.status ? getLabelFromOption(getCurrentValue(c)) : getLabelFromOption(c.status),
                    Description: c.Description && c.Description.length > 200 ? c.Description.substring(0, 200) + "..." : c.Description
                })) : [] }
                columns={ columns }
                exportCSV={true}
                search={ {
                    searchFormatted: true
                  } }
            >
                {
            props =>
            <>
            <ExportCSVButton { ...props.csvProps }>Exporter</ExportCSVButton>
            <BootstrapTable
            { ...props.baseProps }
                bootstrap4
                loading={ isLoading }
                striped
                wrapperClasses="table-responsive"
                remote={ { filter: true, sort: true } }
                onTableChange={ handleTableChange }
                filter={ filterFactory() } 
                pagination={ paginationFactory({ page, sizePerPageList: [10, 20, 50, 100, 200, 2000],sizePerPage: sizePerPageState, totalSize, showTotal: true, paginationTotalRenderer }) }
                cellEdit={ cellEditFactory(cellEdit) }
                bordered={ false }
                noDataIndication="Pas de client"
                overlay={ overlayFactory({ spinner: true, styles: { overlay: (base) => ({...base, background: 'rgba(0, 0, 0, 0.5)'}) } }) }
                remote
            /></>}</ToolkitProvider>
        </div>
        <Modal
            isOpen={modalIsOpen}
            contentLabel="Example Modal"
            style={{ overlay: { zIndex: 1000 }}}
        >            
            <h5>Note pour {currentCustomer.LastName} {currentCustomer.FirstName}</h5>
            <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                <Button
                    onClick={() => {
                        setNotes(`${notes ? notes + '\n' : ""}Note du ${new Date().toLocaleString()}:\n`);
                    }}
                >
                    Dater
                </Button>
            </div>
            <textarea
                style={{ width: "100%", height: "calc(100% - 100px)" }}
                onChange={(e) => {
                    setNotes(e.target.value);
                }}
                value={notes}
            />
            {isSavingNotes ? <CircularProgress size={20} /> : <><Button variant="outlined" onClick={async () => {
                await saveNotes();
                closeModal();
                }} style={{marginRight: 15}} >Fermer</Button>
                <Button variant="contained" onClick={saveNotes}>Enregister</Button></>
            }
      </Modal>
      <Snackbar open={isSavingContactSuccess} autoHideDuration={3000} onClose={() => setIsSavingContactSuccess(false)} message="Sauvegarde réussi" />
      <Snackbar open={isSavingContactFailure} autoHideDuration={3000} onClose={() => setIsSavingContactFailure(false)} message="Echec de la sauvegarde">
      </Snackbar>
    </React.Fragment>
  );
}

export default App;